<template>
  <div class="wrapper mt-5 mb-7 error-page">
    <row>
      <column
        xs="12"
        class="ff-flama fs-lg text-center">
        <h2 class="fs-lg fw-medium mb-4">{{ __('error503.title') }}</h2>
        <h1 class="fs-xxl fw-light tt-uppercase mb-5">
          {{ __('error503.info') }}
        </h1>
        <img
          :src="`${$cdn.resource(`gfx/${$store.state.publication.name}/503.png`)}`"
          alt="503"
          class="mb-7">
        <p v-html="__('error503.message')"></p>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';

export default {
  extends: Page,
};
</script>
